import React, { Component, lazy, Suspense } from 'react';
import './css/App.scss';
import '../../node_modules/leaflet/dist/leaflet.css';
import LoadingSpinner from '../common/LoadingSpinner/LoadingSpinner';
import Header from './../Header/Header.jsx';
import Footer from'./../Footer/Footer.jsx';
import ScrollToTopIcon from './ScrollToTopIcon.jsx';

const Content = lazy(() => import ('./../Content/Content.jsx'));


const renderLoader = () => <div className="m-5 p-5"><LoadingSpinner/></div>;

class Main extends Component {

  componentWillMount() {
    document.title = 'Zeitensprünge - Wien einst und jetzt. Damals und heute im Vergleich.'
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
    });
  }

  render() {
    return <div style={{ position: "relative" }}>
        <Header />
      <Suspense fallback={renderLoader()}>
        <Content />
      </Suspense>
        <Footer />
        <ScrollToTopIcon />
      </div>
      ;
  }
}

export default Main;
//export default withRouter(Main);
