import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { URL_HOME, URL_CONTACT, URL_MAP, URL_DETAIL, URL_DATENSCHUTZ, URL_BEZIRK, URL_ABOUT, URL_MEDIA, URL_FAQ } from '../common/constants.js';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

import { districts } from '../common/constants.js';
import WienKarte from '../Content/WienKarte/WienKarte.jsx';

class Footer extends Component {

  constructor() {
    super();
    
    this.state = {
      highlightedDistrict: null
    };

    this.isLocationDetail = this.isLocationDetail.bind(this);
    this.isLocationHome = this.isLocationHome.bind(this);
    this.isLocationMap = this.isLocationMap.bind(this);

  }
  isLocationMap() {
    return this.props.location.pathname.indexOf(URL_MAP) >= 0;
  }
  isLocationHome() {
    return this.props.location.pathname === URL_HOME;
  }

  isLocationDetail() {
    return this.props.location.pathname.indexOf(URL_DETAIL) >= 0;
  }
  highlightDistrict(district) {
    this.setState({
      highlightedDistrict: district
    })
  }

  render() {
    var footerLinks = districts.map((district, i) => {
      return (
        <li>
          <Link to={`${URL_BEZIRK}/${district.url}`} onMouseOut={() => this.highlightDistrict(null)} onMouseOver={() => this.highlightDistrict(district.districtNumber)}>
            {district.district_number}., {district.name}</Link> {this.district}
        </li>
      );
    });

    const highlightClass = `svg-${this.state.highlightedDistrict}-highlight`;
    return this.isLocationMap() ? <div />
      :
      <footer className={`footer mt-5 ${this.isLocationDetail() ? 'hide-in-landscape' : ''}`}>
        <nav className="py-5 bg-light">
          <div className="container">

             <div className="row small">
              <div className={`d-none d-md-flex col-md-3 justify-center pr-5 ${highlightClass}`}>
                <WienKarte />
              </div>
              <div className="col-12 col-md-9">
                <h5>Historische Ansichten aus deinem Bezirk</h5>
                <div className="row d-none d-md-flex ">
                  <ul className="col list-unstyled">
                    {footerLinks.slice(0, 6)}
                  </ul>
                  <ul className="col list-unstyled">
                    {footerLinks.slice(6, 12)}
                  </ul>
                  <ul className="col list-unstyled">
                    {footerLinks.slice(12, 18)}
                  </ul>
                  <ul className="col list-unstyled">
                    {footerLinks.slice(18, 24)}
                  </ul>
                </div>

                <div className="row d-flex d-md-none ">
                  <ul className="col list-unstyled">
                    {footerLinks.slice(0, 12)}
                  </ul>
                  <ul className="col list-unstyled">
                    {footerLinks.slice(12, 24)}
                  </ul>
                </div>
              </div>
            </div>
             
          </div>
        </nav>
        <div className="bg-dark   py-5">
          <div className="container pb-5">
            <div className="row">
              <div className="col-12 col-sm-4 col-md-6 text-center text-sm-left mb-3 m-sm-0">{this.isLocationDetail()}
                <a className="mx-2 footer-icon" target="_blank" rel="noreferrer noopener" aria-label="Zeitensprünge auf Facebook" href="https://www.facebook.com/ZeitenspruengeWien"><FaFacebook /></a>
                <a className="mx-2 footer-icon" target="_blank" rel="noreferrer noopener" aria-label="Zeitensprünge auf Instagram" href="https://www.instagram.com/zeitenspruenge"><FaInstagram /></a>
                <a className="mx-2 footer-icon" target="_blank" rel="noreferrer noopener" aria-label="Zeitensprünge auf Twitter" href="https://www.twitter.com/zeitenspruenge"><FaTwitter /></a>
              </div>
              <div className="col-12 col-sm-8 col-md-6 mt-2 m-sm-0">
                <div className="row">
                  <div className="col">
                    <h5 className="text-light">Das Projekt</h5>
                    <ul className="list-unstyled">
                      <li><a href={URL_ABOUT}>Über das Projekt</a></li>
                      {/* <li><a href={URL_FAQ}>FAQ</a></li> */}
                      <li><a href={URL_MEDIA}>Medienberichte</a></li>

                    </ul>
                  </div>
                  <div className="col">
                    <h5 className="text-light">Rechtliches</h5>
                    <ul className="list-unstyled">
                      <li><a  href={URL_DATENSCHUTZ}>Datenschutz</a></li>
                      <li><a  href={URL_CONTACT}>Kontakt/Impressum</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  }
}

export default withRouter(Footer);